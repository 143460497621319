import React from 'react';
import './Genieai.css';


function Genieai() {
    return (
        <>
            <div className="main-genieai">
             <div className="projects-container">
                    <div data-aos="fade-up" className="row-gest-one">
                         {/* Column 1 */}
                         <div className="gest-col-1">
                         
                         <h4 className="row-h4">CASE STUDY</h4>
                         <h1 className="row-gest-h1">UX/UI — design for Genie AI, contract intelligence</h1>
                        <ul className="my-role">
                        <h4 className="row-h4">MY ROLE</h4>
                        <div className="my-role-list">
                            <li>Creative Direction</li>
                            <li>UX / UI</li>
                            <li>Website Design</li>
                            <li>Icon Design</li>
                            </div>
                                    </ul>
                        <ul className="client"> 
                        <h4 className="row-h4">CLIENT</h4>
                        <li>Genie AI</li></ul>

                        <ul className="year"> 
                        <h4 className="row-h4">YEAR</h4>
                        <li>2019-2020</li></ul>
                                            
                      </div>
                
    
        
    
                    {/* Column 2 */}
                    <div className="gest-col-2">
                        <ul className="list-project-info">
                        <li className="list-project-info-text">The Genie Community is an eco-system of lawyers and business people coming together,
on one platform, to share their experience and insight. <strong>Genie Docs</strong> A new type of document with intelligence built in. The more people draft with Genie Docs, the smarter it gets, meaning in time the technology will provide unparalleled legal and commercial insights across the market.</li>
                       
<li className="list-project-info-text">The client wanted to create a landing page to show all benefits of the platform to the visitors and when they know it. They can ask an invitation to the Genie AI community.
</li>
                        {/* <li><a className="footer-link" href="https://www.behance.net/gallery/88322561/Sellfashion-laxury-seller-uiux-design" target="_blank" rel="noopener noreferrer">View this project on Behance &#8594;</a></li> */}
                      </ul>
                       
                    </div>
                </div>
                <div data-aos="fade-up" className="genieai-images">
                <img className="project-in-img" src={require('../../images/Genieai/Genieai1.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Genieai/Genieai2.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Genieai/Genieai3.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Genieai/Genieai4.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Genieai/Genieai5.jpg')} alt="sf" />
                </div>
               
                {/* <hr /> */}
            </div>
        </div>
        </>
    );
}

export default Genieai;