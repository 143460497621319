import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  
body {
    background: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryLight};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
    justify-content: center;
    text-rendering: optimizeLegibility;
  }


.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}

.content-wrap {
  position: relative;
  min-height: 100%;
  flex: 1;
}

`