import React from 'react';
import './Snailmates.css';


function Snailmates() {
    return (
        <>
            <div className="main-snailmates">
             <div className="projects-container">
                    <div data-aos="fade-up" className="row-gest-one">
                         {/* Column 1 */}
                         <div className="gest-col-1">
                         <h4 className="row-h4">CASE STUDY</h4>
                         <h1 className="row-gest-h1">UX/UI — design for SnailMates, travel application</h1>
                        <ul className="my-role">
                        <h4 className="row-h4">MY ROLE</h4>
                        <div className="my-role-list">
                            <li>Creative Direction</li>
                            <li>UX / UI</li>
                            <li>Mobile Design</li>
                            <li>Logo Design</li>
                            </div>
                                    </ul>
                        <ul className="client"> 
                        <h4 className="row-h4">CLIENT</h4>
                        <li>SnailMates</li></ul>

                        <ul className="year"> 
                        <h4 className="row-h4">YEAR</h4>
                        <li>2020</li></ul>
                                            
                      </div>
                
    
        
    
                    {/* Column 2 */}
                    <div className="gest-col-2">
                        <ul className="list-project-info">
                        <li className="list-project-info-text"><strong>SnailMates</strong> traveling application. The client wanted to create a modern traveling application for people who want to find mates to travel together to Australia by van quickly. The main problem that had to be solved was allowing the customer to be an organizer or mate for travel, not thinking about finding mates but planning trips and not worrying about many documents for rent minivans.</li>
                    
                       
                        <li><a className="footer-link" href="https://www.behance.net/gallery/105867233/Snail-Mates-travel-application" target="_blank" rel="noopener noreferrer">View this project on Behance &#8594;</a></li>
                      </ul>
                       
                    </div>
                </div>
                <div className="snailmates-images">
                <div className="videoWrapper">
                <iframe title="snailmates presentation" data-aos="fade-up" className="project-in-video" src="https://player.vimeo.com/video/467189618?autoplay=0&loop=1&title=0&byline=0&portrait=0" frameborder="0" allowfullscreen></iframe>
                </div>
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Snailmates/1.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/2.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/3.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/4.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/5.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/6.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/7.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/8.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/9.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/10.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/11.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/12.jpg')} alt="Snailmates" />
                <img className="project-in-img" src={require('../../images/Snailmates/13.jpg')} alt="Snailmates" />
                </div>
               
                {/* <hr /> */}
            </div>
        </div>
        </>
    );
}

export default Snailmates;