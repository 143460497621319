import React from 'react'
import './Insta.css'

const Insta = props => (
    <div className="Insta">
        <img className="Instalogo" src={props.image2} alt="instagram" />
        <img className="Instaimage" src={props.image} alt="instagram" />
        <h3 class="Insta1">{props.title}</h3>
        <p class="Insta2">{props.text}</p>
    </div>
)

export default Insta