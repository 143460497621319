import React, { useEffect } from 'react';
import Home from './pages/Home';
import { ThemeProvider } from 'styled-components';
// import { useOnClickOutside } from './hooks';
import { GlobalStyles } from './global';
import { theme } from './theme'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Gestorsolar from './pages/Projects/Gestorsolar';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import ScrollToTop from './ScrollToTop';
import Farmday from './pages/Projects/Farmday';
import Liveshop from './pages/Projects/Liveshop';
import Snailmates from './pages/Projects/Snailmates';
import Spaceonline from './pages/Projects/Spaceonline';
import Electroneek from './pages/Projects/Electroneek';
import Sellfashion from './pages/Projects/Sellfashion';
import Turnkey from './pages/Projects/Turnkey';
import Genieai from './pages/Projects/Genieai';
import Aboutme from './pages/Aboutme';
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({duration: 2000, offset: 100, once: true});
  }, []);
  // const [open, setOpen] = useState(false);
  // const node = useRef();
  // useOnClickOutside(node, () => setOpen(false));
  return (
    <Router>
    <Switch> 
    <ThemeProvider theme={theme}>
    <div className="page-container">
    <div className="content-wrap">
    <div>
    <GlobalStyles />
   
      <ScrollToTop />
      <Header class="header" /> 
        <Route path="/" exact component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path='/farmday' component={Farmday} />
        <Route path='/liveshop' component={Liveshop} />
        <Route path='/snailmates' component={Snailmates} />
        <Route path='/spaceonline' component={Spaceonline} />
        <Route path='/electroneek' component={Electroneek} />
        <Route path='/gestorsolar' component={Gestorsolar} />
        <Route path='/sellfashion' component={Sellfashion} />
        <Route path='/turnkey' component={Turnkey} />
        <Route path='/genieai' component={Genieai} />
        <Route path='/projects' component={Projects} />
        <Route path='/aboutme' component={Aboutme} />
        </div>
        </div>
        <Footer class="footer" />
    </div>
    </ThemeProvider>
    </Switch>
    </Router>
  );
}

export default App;