import styled from 'styled-components';

export const StyledMenu = styled.nav`
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(+100%)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background-color: rgba(0, 0, 0, 0.5);;
  height: 100%;
  width: 320px;
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 2;
  backdrop-filter: blur(5px);

  .container-menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
  ${'' /* body {
    background: url(https://images.unsplash.com/photo-1544306094-e2dcf9479da3) no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100vh;
} */}

.icons-menu {
  width: 40px;
}

${'' /* .container {
    width: 30rem;
    height: 20rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);   
    border-radius: 5px;
    position: relative;
    z-index: 1;
    background: inherit;
    overflow: hidden;
}

.container:before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(10px);
    margin: -20px;
} */}

  img {
    vertical-align: top;
    color: #ffffff;
  }

  a {
    opacity: 100%;
    vertical-align: center;
    font-size: 2rem;
    padding: 1rem 0;
    font-weight: bold;
    letter-spacing: 0.2rem;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s linear;
  }

  a:hover {
    color: #878a8f;
  }


.profile {
  vertical-align: top;
}

.profile g {
  stroke: #000000;
  fill: #000000;

}

.profile svg:hover {
 }

 .bobo123 {
   fill: black;
 }

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: left;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }

  ${'' /* @media (min-width: 1600px) and (max-width: 1750px) {
    .hero-container-h3 {
    padding-top: 300px;
    }
  } */}

  @media (max-width: 1799px) {
    a {
    opacity: 100%;
    vertical-align: center;
    font-size: 1.8rem;
    padding: 1rem 0;
    font-weight: bold;
    letter-spacing: 0.1 rem;
  }
  .icons-menu {
  width: 35px;
}
    }
    @media (max-width: 1400px) {
    width: 300px;
    a {
    opacity: 100%;
    vertical-align: center;
    font-size: 26px;
    padding: 0.8rem 0;
    font-weight: bold;
    letter-spacing: 0.1 rem;
  }
  .icons-menu {
  width: 30px;
  }
   }
   @media (max-width: 1199px) {
    width: 270px;
    a {
    opacity: 100%;
    vertical-align: center;
    font-size: 26px;
    padding: 0.8rem 0;
    font-weight: bold;
    letter-spacing: 0.1 rem;
  }
  .icons-menu {
  width: 30px;
  }
   }

   @media (max-width: 599px) {
    width: 100%;
    align-items: center;
    a {
    opacity: 100%;
    vertical-align: center;
    font-size: 26px;
    padding: 0.8rem 0;
    font-weight: bold;
    letter-spacing: 0.1 rem;
  }
  .menu-width {
    width: 200px;
  }
  .icons-menu {
  width: 30px;
  }
   }
  }
`;

