import React from 'react';
import './HeroSection.css';
import { Link } from 'react-router-dom';
import Card from './Card';
import Insta from './Insta';
// import desktopImage from '../images/hero.jpg';
// import mobileImage from '../images/hero.jpg';

  

function HeroSection() {
  // const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;
    return (
      <>
    
  <div className="background">
  <img className="desktop_image_1" src={require('..//images/hero.jpg')} width="100%"  alt="Bacgkround" />
  <img className="desktop_image_2" src={require('..//images/hero2.jpg')} width="100%"  alt="Bacgkround" />
  <img className="desktop_image_3" src={require('..//images/hero3.jpg')} width="100%"  alt="Bacgkround" />
  <img className="tablet_image_1" src={require('..//images/hero4.jpg')} width="100%"  alt="Bacgkround" />
  <img className="nobile_image_1" src={require('..//images/hero5.jpg')} width="100%"  alt="Bacgkround" />
  </div>
  <div className="hero-container">
  <div data-aos="fade-up">
  <h3 className="hero-container-h3">DMYTRO KULYKOV</h3>
  <h1 className="hero-container-h1">Website and user
interface&nbsp;designer</h1>
  <p className="hero-container-p">Over the past 6 years, as a Product designer, 
        I’ve worked with companies and startups to successfully created new apps 
        and web interfaces which reach their full potential.</p>
   <div className="Links">
   <Link className="Link" to="/projects">View Projects</Link>
   <h4 className="or">or</h4>
   <Link className="Link" to="/aboutme">Read About Me</Link>
    </div>
    <div className="HeroUIUX">
        <img className="HeroUIUX-img" src={require('../images/Designeruxui.svg')} alt="sf" />
    </div>
    <div className="H22">
        <img className="H22-img" src={require('../images/Rectangle1.svg')} alt="sf" />
    </div>
        <div className="Logos">
          <img className="Logos-img" src={require('../images/logo-sketch.png')} alt="sf" />
          <img className="Logos-img" src={require('../images/logo-figma.png')} alt="sf" />
          <img className="Logos-img" src={require('../images/logo-react.png')} alt="sf" />
          <img className="Logos-img" src={require('../images/logo-photoshop.svg')} alt="sf" />
          <img className="Logos-img" src={require('../images/logo-illustrator.svg')} alt="sf" />
          <img className="Logos-img" src={require('../images/logo-swift.png')} alt="sf" />
        </div>
        </div>
        <div data-aos="fade-up" className="Cards">
      <h6 className="hero-container-h6">SELECTED PROJECTS</h6>
      <h5 className="hero-container-h5">Case studies</h5>
      </div>
        <div className="CardGroup">
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Farmday">
        <Card
          title="Product design for Farm Day, E&#8209;commerce Marketplace"
          text="2024"
          text2="View This Project &#8594;"
          image={require('../images/projects/Farmday.jpg')} />
        </Link>
        </div>
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Liveshop">
        <Card
          title="Product design for LiveShop, streaming platform"
          text="2023"
          text2="View This Project &#8594;"
          image={require('../images/projects/Liveshop.jpg')} />
        </Link>
        </div>
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Snailmates">
        <Card
          title="UX/UI — design for SnailMates, travel application"
          text="2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Snailmates.jpg')} />
        </Link>
        </div>
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Spaceonline">
        <Card
          title="UX/UI — design for Space Online, Web coaching platform"
          text="2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Spaceonline.jpg')} />
        </Link>
        </div>
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Electroneek">
        <Card 
          title="UX/UI — design for Electroneek, RPA platform"
          text="2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Electroneek.jpg')} />
          </Link>
          </div>
          <div data-aos="fade-up">
           <Link className="Linktoproject" to="./Gestorsolar">
        <Card 
          title="UX/UI — design for Gestorsolar, logic platform for sun batteries"
          text="2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Gestrosolar.jpg')} />
        </Link>
        </div>
        <div data-aos="fade-up">
        <Link className="Linktoproject" to="./Sellfashion">
        <Card 
          title="UX/UI — design for SellFashion, online store of clothes"
          text="2019-2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Clothes.jpg')} />
          </Link>
          </div>
        {/* <Card 
          title="Basic UX — desogn resources for InVision Studio"
          text="2019-2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Ink.jpg')} /> */}
          <div data-aos="fade-up">
          <Link className="Linktoproject" to="./Turnkey">
          <Card 
          title="UX/UI — design for TurnKey, startup"
          text="2019-2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Turnkey.jpg')} />
          </Link>
          </div>
          {/* <div data-aos="fade-up">
          <Link className="Linktoproject" to="./Genieai">
        <Card 
          title="UX/UI — design for Genie AI, contract intelligence"
          text="2019-2020"
          text2="View This Project &#8594;"
          image={require('../images/projects/Genieai.jpg')} />
          </Link>
          </div> */}
      </div>
      <div data-aos="fade-up" className="Instagram">
      <h6 className="hero-container-h6">WHAT’S NEW?</h6>
      <h5 className="hero-container-h5">Latest on Instagram</h5>
      </div>
      <div data-aos="fade-up" className="InstaGroupCard">
      <a className="linktoinsta" href="https://www.instagram.com/p/CNw_nA3DIVm/" target="_blank" rel="noopener noreferrer">
        <Insta
          image2={require('../images/instaicon.svg')}
          title="Worked on the new App. One of the concepts. 😋😋😋"
          text="Go to Instagram &#8594;"
          image={require('../images/Insta1.jpg')} />
          </a>
          <a className="linktoinsta" href="https://www.instagram.com/p/CNw4NyUD7Cg/" target="_blank" rel="noopener noreferrer">
        <Insta
          image2={require('../images/instaicon.svg')}
          title="UX/UI Design for Space Online - coaching platform.Space Online coaching platform. The client wanted to create a flexible..."
          text="Go to Instagram &#8594;"
          image={require('../images/Insta2.jpg')} />
          </a>
          <a className="linktoinsta" href="https://www.instagram.com/p/CNw3R8FDGVF/" target="_blank" rel="noopener noreferrer">
        <Insta
          image2={require('../images/instaicon.svg')}
          title="UX/UI Design for ElectroNeek, an Intelligent Automation company backed by Y Combinator, develops products to automatically discover..."
          text="Go to Instagram &#8594;"
          image={require('../images/Insta3.jpg')} />
          </a>
      </div>
      <div data-aos="fade-up" className="Wrapper1">
        <h2 className='bobo'>NEED A DESIGNER?</h2>
        <div className="large">
        <a href="Contact" class="internal-with-icon">Let’s work together&nbsp;<img alt="arrowlink" className="arrowlink" src={require('../images/arrowlink.svg')} width="50" /></a>
        </div>
      </div>
      
</div>
</>

    );
}

export default HeroSection
