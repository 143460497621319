import React from 'react';
import HeroSection from '../components/HeroSection';


function Home () {
    return (
        <>
<HeroSection />
        </>
    );
}

export default Home;