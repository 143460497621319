import React from 'react';
import './Contact.scss';
import Axios from 'axios';
// import { FormErrors } from './FormErrors';

const initialState = {
  fname: '',
  lname: '',
  tel: '',
  email: '',
  selectedOption: '',
  message: '',
  formErrors: {fname: '', lname: '', email: '', message: ''},
  fnameValid: false,
  emailValid: false,
  messageValid: false,
  disabled: false,
  emailSent: null,
}

class Contact extends React.Component {
  state = initialState;
     
  
      // handleChange = (event) => {
      //   const target = event.target;
      //   const value = target.type === 'checkbox' ? target.checked : target.value;
      //   const name = target.name;

  
      //   this.setState({
      //     [name]: value
      //   });
      // }
    
      validate = () => {
      //  let fnameError= 'Name is empty';
      //  let lnameError= 'Last Name is empty';
      //  let telError= 'Telephone number is not added';
       let emailError= '';
      //  let messageError= 'Message is empty';

       if (!this.state.email.includes("@")) {
         emailError = 'invalid email';
       }

       if (emailError) {
         this.setState({ emailError });
         return false;
       }
       return true;
      };

      handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
                      () => { this.validateField(name, value) });
      }

      validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let fnameValid = this.state.fnameValid;
        let lnameValid = this.state.lnameValid;
        let emailValid = this.state.emailValid;
        // let telValid = this.state.telValid;
        let messageValid = this.state.messageValid;

    
        switch(fieldName) {
          case 'fname':
            fnameValid = value.length >= 2;
            fieldValidationErrors.fname = fnameValid ? '': ' is too short';
            break;
            case 'lname':
            lnameValid = value.length >= 2;
            fieldValidationErrors.lname = lnameValid ? '': ' is too short';
            break;
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : ' is invalid';
            break;
            // case 'tel':
            //   telValid = value.match(/^[0-9]+$/);
            //   fieldValidationErrors.tel = telValid ? '' : ' only numbers';
            //   break;
            case 'message':
              messageValid = value.length >= 20;
              fieldValidationErrors.message = messageValid ? '': ' is too short';
              break;
          default:
            break;
        }
        this.setState({  formErrors: fieldValidationErrors,
                         fnameValid: fnameValid,
                         lnameValid: lnameValid,
                         emailValid: emailValid,
                        //  telValid: telValid
                        messageValid: messageValid
                      }, this.validateForm);
      }

      validateForm() {
        this.setState({formValid: this.state.fnameValid && this.state.fnameValid && this.state.emailValid && this.state.messageValid});
      }
  
      handleOptionChange = changeEvent => {
        this.setState({
          selectedOption: changeEvent.target.value
        });
      };

      handleSubmit = (event) => {
        event.preventDefault();
        const isValid = this.validate();
        if (isValid) {
        console.log(this.state);
         // clear form
        this.setState(initialState);
        }
        
        this.setState({
          disabled: true
        });


      Axios.post('https://salty-sea-03486.herokuapp.com/api/email', this.state)
      .then(res => {
        if(res.data.success) {
          this.setState({
            disabled: false,
            emailSent: true
          });
        } else {
          this.setState({
            disabled: false,
            emailSent: false
          });
        }
      })
      .catch(err => {
        console.log(err);

        this.setState({
          disabled: false,
          emailSent: false
        });
      })
}
errorClass(error) {
  return(error.length === 0 ? '' : 'has-error');
}
    render() {
      
        return(
        


        <div className="contact-page">

        {/* <div className="App">
            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
              </div>
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div> */}

 
        <div data-aos="fade-up" className="contact-form">

        <div id="contact-container">
        <div className="contact-info">
        <h4 className="contact-text">
            Contact Information
        </h4>
        <p className="contact-fill">Fill up the form and the click Send Message</p>
        <div className="icon-text">
        <i className="fa fa-phone"></i>
            <span>+ 48 (452) 091 679</span>
        </div>

        <div className="icon-text">
        <i className="fa fa-envelope"></i>
            <span>myzikano@gmail.com</span>
        </div>
        <div className="icon-text">
        <i className="fa fa-map-marker-alt"></i>
            <span>Warsaw, Poland</span>
        </div>

        <div className="social-media">
            <a href="https://www.facebook.com/dima.legalas" target="_blank" rel="noopener noreferrer" className="icon-circle">
            <i className="fa fa-facebook"></i>
            </a>
            <a href="https://www.linkedin.com/in/dmytro-kulykov-916912181/" target="_blank" rel="noopener noreferrer" className="icon-circle">
            <i className="fa fa-linkedin"></i>
            </a>
            <a href="https://www.instagram.com/myzikano/" target="_blank" rel="noopener noreferrer" className="icon-circle">
            <i className="fa fa-instagram"></i>
            </a>
            <a href="https://www.behance.net/Myzikano" target="_blank" rel="noopener noreferrer" className="icon-circle">
            <i className="fa fa-behance-square"></i>
            </a>
            </div>
        </div>


        <form onSubmit={this.handleSubmit}>
        {/* <div><FormErrors formErrors={this.state.formErrors} /></div> */}
        <div className="col">
        <div className={`form-group ${this.errorClass(this.state.formErrors.fname)}`}>
        <div className="validation">
        <label htmlFor="fname">First Name*</label><div className="validation-error">{this.state.formErrors.fname}</div>
        </div>
        <input id="fname" name="fname" type="text" value={this.state.fname} onChange={this.handleUserInput} />
        </div>
        <div className={`form-group ${this.errorClass(this.state.formErrors.lname)}`}>
        <div className="validation">
        <label htmlFor="lname">Last Name*</label><div className="validation-error">{this.state.formErrors.lname}</div>
        </div>
        <input id="lname" name="lname" type="text" value={this.state.lname} onChange={this.handleUserInput} />
        </div>
        </div>
        <div className="col">
        <div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
        <div className="validation">
        <label htmlFor="email">E-Mail*</label><div className="validation-error">{this.state.formErrors.email}</div>
        </div>
        <input id="email" name="email" type="text" as="text" value={this.state.email} onChange={this.handleUserInput} />
        </div>
        <div className="form-group">
        <div className="validation">
        <label htmlFor="tel">Phone</label><div className="validation-error">{this.state.formErrors.tel}</div>
        </div>
        <input id="tel" name="tel" type="tel" value={this.state.tel} onChange={this.handleUserInput} />
        </div>
        </div>

        <div className="col">
            <div className="form-group solo">
            
             <label>What offer do you want to discuss?</label>
                 <div id="radio-buttons">
                <div className="radio-button">
            
                <input className="rad1" type="radio" id="hire" name="offer" value="Hire me full-time" checked={this.state.selectedOption === 'Hire me full-time'} onChange={this.handleOptionChange} /><label htmlFor="hire">Hire me full-time</label>
                </div>
                <div className="radio-button">
                <input className="rad1" type="radio" id="long" name="offer" value="Long term partnership" checked={this.state.selectedOption === 'Long term partnership'} onChange={this.handleOptionChange} /><label htmlFor="long">Long term partnership</label>
                </div>
                <div className="radio-button">
                <input className="rad1" type="radio" id="say" name="offer" value="Say hi!" checked={this.state.selectedOption === 'Say hi!'} onChange={this.handleOptionChange} /><label htmlFor="say">Say hi!</label>
                </div>
                </div>
                
            </div>
        </div>
        
        <div className="col">
        <div className="form-group solo">
        <div className="validation">
        <label htmlFor="message">Message*</label><div className="validation-error">{this.state.formErrors.message}</div>
        </div>
        <textarea id="message" name="message" as="textarea" value={this.state.message} onChange={this.handleUserInput}></textarea>
        </div>
        </div>

        <div className="col">
        <div className="form-group solo right">
      
        <div className="buttonwithtext"> 
        {this.state.emailSent === true && <p className="d-inline sucess-msg">Email Sent</p>}
        {this.state.emailSent === false && <p className="d-inline err-msg">Email Not Sent</p>}
        <button className="primary" type="submit" disabled={!this.state.formValid} >Send Message</button>
        </div>
       

        </div>
        </div>

        </form>
        </div>
        </div>
        </div>

        // </>
    );
    }

// onNameChange(event) {
//     this.setState({name: event.target.value})
//   }

//   onEmailChange(event) {
//     this.setState({email: event.target.value})
//   }

//   onMessageChange(event) {
//     this.setState({message: event.target.value})
//   }

//   handleSubmit(event) {
//   }
}

export default Contact;