import React from 'react';
import './Card.css'
import "aos/dist/aos.css";

const Card = props => (
    <div data-aos="fade-up" className="Card">
        <img src={props.image} alt="project" />
        <div className="Card1">{props.text}</div>
        <div className="Card2">{props.title}</div>
        <div className="Card3">{props.text2}</div>
    </div>
)

export default Card