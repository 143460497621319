// Menu.js
import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import { Link } from 'react-router-dom';
// import { ReactComponent as Sprite } from "../../images/Profile.svg";

const Menu = ({ open }) => {
  return (
    <div className="container-menu">
    <StyledMenu open={open}>
     <Link className="menu-width" to="projects">
     <span role="img" aria-label="Home"><img className="icons-menu" src={require('../../images/Projects.svg')} alt="sf" />&nbsp;&nbsp;</span>
     Projects </Link>
     <Link className="menu-width" to="aboutme">
     <span role="img" aria-label="Home"><img className="icons-menu" src={require('../../images/Profile.svg')} alt="sf" />&nbsp;&nbsp;</span>
     About Me </Link>
     {/* <div className="bobo123">
     <Link to="/">
     <span><Sprite className="profile" />&nbsp;&nbsp;About me </span></Link></div> */}
     <Link className="menu-width" to="contact">
     <span role="img" aria-label="Contact"><img className="icons-menu" src={require('../../images/Contact.svg')} alt="sf" />&nbsp;&nbsp;</span>
     Contact </Link>
     <a className="menu-width" href="https://dribbble.com/Myzikano" target="_blank" rel="noopener noreferrer">
     <span role="img" aria-label="Home"><img className="icons-menu" src={require('../../images/Dribbble.svg')} alt="sf" />&nbsp;&nbsp;</span>
     Dribble </a>
     <a className="menu-width" href="https://www.instagram.com/myzikano/" target="_blank" rel="noopener noreferrer">
     <span role="img" aria-label="Home"><img className="icons-menu" src={require('../../images/Instagram.svg')} alt="sf" />&nbsp;&nbsp;</span>
     Instagram </a>
     <a className="menu-width" href="https://www.behance.net/Myzikano" target="_blank" rel="noopener noreferrer">
     <span role="img" aria-label="Home"><img className="icons-menu" src={require('../../images/Behance.svg')} alt="sf" />&nbsp;&nbsp;</span>
     Behance </a>
    </StyledMenu>
    </div>
  )
}
Menu.propTypes = {
  open: bool.isRequired,
}
export default Menu;