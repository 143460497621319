import React from 'react';
import './Electroneek.css';


function Electroneek() {
    return (
        <>
            <div className="main-electroneek">
             <div className="projects-container">
                    <div data-aos="fade-up" className="row-gest-one">
                         {/* Column 1 */}
                         <div className="gest-col-1">
                         
                         <h4 className="row-h4">CASE STUDY</h4>
                         <h1 className="row-gest-h1">UX/UI — design for Electroneek, RPA platform</h1>
                        <ul className="my-role">
                        <h4 className="row-h4">MY ROLE</h4>
                        <div className="my-role-list">
                            <li>Creative Direction</li>
                            <li>UX / UI</li>
                            <li>Website Design</li>
                            <li>Icon Design</li>
                            </div>
                                    </ul>
                        <ul className="client"> 
                        <h4 className="row-h4">CLIENT</h4>
                        <li>Electroneek</li></ul>

                        <ul className="year"> 
                        <h4 className="row-h4">YEAR</h4>
                        <li>2020</li></ul>
                                            
                      </div>
                
    
        
    
                    {/* Column 2 */}
                    <div className="gest-col-2">
                        <ul className="list-project-info">
                        <li className="list-project-info-text"><strong>ElectroNeek</strong>, an Intelligent Automation company backed by Y Combinator, develops products to automatically discover, analyze, and robotize repetitive business processes.</li>
                       
<li className="list-project-info-text">After the company received the investment and entered the world market, they needed a rebranding of the site, since the old one did not focus on the company's tariffs and services. In the new site, the pages of the company's tariffs, the pages of investors and company's owners, the main page with the presentation of the product, the page of bonuses from the purchase of the product were worked out. On this moment, main site updated more information and product versions, main design was changed with all pages.
</li>
                        <li><a className="footer-link" href="https://www.behance.net/gallery/99840463/ElectroNeek-Website-Design" target="_blank" rel="noopener noreferrer">View this project on Behance &#8594;</a></li>
                      </ul>
                       
                    </div>
                </div>
                <div className="electroneek-images">
                <div className="videoWrapper">
                <iframe title="electroneek presentation" data-aos="fade-up" className="project-in-video" src="https://player.vimeo.com/video/434093445?autoplay=0&loop=1&title=0&byline=0&portrait=0" frameborder="0" allowfullscreen></iframe>
                </div>
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Electroneek/Electroneek1.jpg')} width="1400px" alt="sf" />
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Electroneek/Electroneek2.jpg')} width="1400px" alt="sf" />
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Electroneek/Electroneek3.jpg')} width="1400px" alt="sf" />
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Electroneek/Electroneek4.jpg')} width="1400px" alt="sf" />
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Electroneek/Electroneek5.jpg')} width="1400px" alt="sf" />
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Electroneek/Electroneek6.jpg')} width="1400px" alt="sf" />
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Electroneek/Electroneek7.jpg')} width="1400px" alt="sf" />
                </div>
               
                {/* <hr /> */}
            </div>
        </div>
        </>
    );
}

export default Electroneek;