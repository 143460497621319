import React from 'react';
import './Sellfashion.css';


function Sellfashion() {
    return (
        <>
            <div className="main-sellfashion">
             <div className="projects-container">
                    <div data-aos="fade-up" className="row-gest-one">
                         {/* Column 1 */}
                         <div className="gest-col-1">
                         
                         <h4 className="row-h4">CASE STUDY</h4>
                         <h1 className="row-gest-h1">UX/UI — design for SellFashion, online store of clothes</h1>
                        <ul className="my-role">
                        <h4 className="row-h4">MY ROLE</h4>
                        <div className="my-role-list">
                            <li>Creative Direction</li>
                            <li>UX / UI</li>
                            <li>Website Design</li>
                            <li>Icon Design</li>
                            </div>
                                    </ul>
                        <ul className="client"> 
                        <h4 className="row-h4">CLIENT</h4>
                        <li>Sellfashion</li></ul>

                        <ul className="year"> 
                        <h4 className="row-h4">YEAR</h4>
                        <li>2019-2020</li></ul>
                                            
                      </div>
                
    
        
    
                    {/* Column 2 */}
                    <div className="gest-col-2">
                        <ul className="list-project-info">
                        <li className="list-project-info-text">Internet platform the <strong>SellFashion</strong> was created for the sale of second-hand branded luxury clothing. The user of this site had to not only view the information about the clothes in detail, but also be able to leave reviews and leave questions about the product. This platform is also a guarantor of transactions.</li>
                       
                        <li><a className="footer-link" href="https://www.behance.net/gallery/88322561/Sellfashion-laxury-seller-uiux-design" target="_blank" rel="noopener noreferrer">View this project on Behance &#8594;</a></li>
                      </ul>
                       
                    </div>
                </div>
                <div data-aos="fade-up" className="sellfashion-images">
                <img className="project-in-img" src={require('../../images/Sellfashion/Behance-Sellfashion.jpg')} width="1400px" alt="sf" />
                </div>
               
                {/* <hr /> */}
            </div>
        </div>
        </>
    );
}

export default Sellfashion;