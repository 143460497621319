import React from 'react';
import './Liveshop.css';


function Liveshop() {
    return (
        <>
            <div className="main-liveshop">
             <div className="projects-container">
                    <div data-aos="fade-up" className="row-gest-one">
                         {/* Column 1 */}
                         <div className="gest-col-1">
                         <h4 className="row-h4">CASE STUDY</h4>
                         <h1 className="row-gest-h1">Product design for LiveShop, streaming platform</h1>
                        <ul className="my-role">
                        <h4 className="row-h4">MY ROLE</h4>
                        <div className="my-role-list">
                            <li>Creative Direction</li>
                            <li>Product Design</li>
                            <li>Mobile Design</li>
                            <li>Logo Design</li>
                            <li>UX / UI</li>
                            </div>
                                    </ul>
                        <ul className="client"> 
                        <h4 className="row-h4">CLIENT</h4>
                        <li>LiveShop</li></ul>

                        <ul className="year"> 
                        <h4 className="row-h4">YEAR</h4>
                        <li>2023</li></ul>
                                            
                      </div>
                
    
        
    
                    {/* Column 2 */}
                    <div className="gest-col-2">
                        <ul className="list-project-info">
                        <li className="list-project-info-text"><strong>LiveShop</strong> re-imagines the mobile e-commerce
experience by bringing immersive and entertaining
real-time boutique shopping straight to your phone.
LiveShop is a mobile, live-streaming marketplace
where up-and-coming brands and individuals can
create and host their own pop-up shopping channels
and sell directly to a global audience.</li>
                    
                       
                        <li><a className="footer-link" href="https://www.behance.net/gallery/184086863/LiveShops-live-streaming-marketplace" target="_blank" rel="noopener noreferrer">View this project on Behance &#8594;</a></li>
                      </ul>
                       
                    </div>
                </div>
                <div className="liveshop-images">
                <div className="videoWrapper">
                 {/* 
                 <iframe title="Liveshops presentation" data-aos="fade-up" className="project-in-video" src="https://player.vimeo.com/video/467189618?autoplay=0&loop=1&title=0&byline=0&portrait=0" frameborder="0" allowfullscreen></iframe>
                
                */}
                </div>
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Liveshop/1.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/2.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/3.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/4.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/5.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/6.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/7.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/8.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/9.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/10.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/11.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/12.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/13.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/14.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/15.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/16.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/17.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/18.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/19.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/20.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/21.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/22.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/23.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/24.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/25.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/26.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/27.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/28.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/29.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/30.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/31.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/32.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/33.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/34.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/35.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/36.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/37.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/38.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/39.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/40.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/41.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/42.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/43.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/44.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/45.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/46.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/47.jpg')} alt="Liveshops" />
                <img className="project-in-img" src={require('../../images/Liveshop/48.jpg')} alt="Liveshops" />
                </div>
               
                {/* <hr /> */}
            </div>
        </div>
        </>
    );
}

export default Liveshop;