import React from 'react';
import './Spaceonline.css';


function Spaceonline() {
    return (
        <>
            <div className="main-spaceonline">
             <div className="projects-container">
                    <div data-aos="fade-up" className="row-gest-one">
                         {/* Column 1 */}
                         <div className="gest-col-1">
                         <h4 className="row-h4">CASE STUDY</h4>
                         <h1 className="row-gest-h1">UX/UI — design for Web coaching platform</h1>
                        <ul className="my-role">
                        <h4 className="row-h4">MY ROLE</h4>
                        <div className="my-role-list">
                            <li>Creative Direction</li>
                            <li>UX / UI</li>
                            <li>Website Design</li>
                            <li>Logo Design</li>
                            </div>
                                    </ul>
                        <ul className="client"> 
                        <h4 className="row-h4">CLIENT</h4>
                        <li>Space Online</li></ul>

                        <ul className="year"> 
                        <h4 className="row-h4">YEAR</h4>
                        <li>2020</li></ul>
                                            
                      </div>
                
    
        
    
                    {/* Column 2 */}
                    <div className="gest-col-2">
                        <ul className="list-project-info">
                        <li className="list-project-info-text"><strong>Space Online</strong> coaching platform. The client wanted to create a flexible and modern online platform for coach specialists of various 
                        specialties. Most of them are psychologists. The main problem that had to be solved was to provide the client with the opportunity to quickly get acquainted with the practice, 
                        quickly select a coach or specialist to their liking, choose free time in the schedule and quickly go to their personal account for online communication.</li>
                    
                       
                        <li><a className="footer-link" href="https://www.behance.net/gallery/105500297/Space-Online-Web-coaching-platform" target="_blank" rel="noopener noreferrer">View this project on Behance &#8594;</a></li>
                      </ul>
                       
                    </div>
                </div>
                <div className="spaceonline-images">
                <div className="videoWrapper">
                <iframe title="space online presentation" data-aos="fade-up" className="project-in-video" src="https://player.vimeo.com/video/464922703?autoplay=0&loop=1&title=0&byline=0&portrait=0" frameborder="0" allowfullscreen></iframe>
                </div>
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Spaceonline/1.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/2.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/3.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/4.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/5.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/6.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/7.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/8.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/9.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/10.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/11.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/12.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/13.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/14.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/15.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/16.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/17.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/18.jpg')} alt="Spaceonline" />
                <img className="project-in-img" src={require('../../images/Spaceonline/19.jpg')} alt="Spaceonline" />
                </div>
               
                {/* <hr /> */}
            </div>
        </div>
        </>
    );
}

export default Spaceonline;