import React from 'react';
import './Gestorsolar.css';


function Gestorsolar() {
    return (
        <>
            <div className="main-gestrosolar">
             <div className="projects-container">
                    <div data-aos="fade-up" className="row-gest-one">
                         {/* Column 1 */}
                         <div className="gest-col-1">
                         
                         <h4 className="row-h4">CASE STUDY</h4>
                         <h1 className="row-gest-h1">UI/UX Design for Gestorsolar</h1>
                        <ul className="my-role">
                        <h4 className="row-h4">MY ROLE</h4>
                        <div className="my-role-list">
                            <li>Creative Direction</li>
                            <li>UX / UI</li>
                            <li>Website Design</li>
                            <li>Icon Design</li>
                            </div>
                                    </ul>
                        <ul className="client"> 
                        <h4 className="row-h4">CLIENT</h4>
                        <li>Gestorsolar</li></ul>

                        <ul className="year"> 
                        <h4 className="row-h4">YEAR</h4>
                        <li>2019-2020</li></ul>
                                            
                      </div>
                
    
        
    
                    {/* Column 2 */}
                    <div className="gest-col-2">
                        <ul className="list-project-info">
                        <li className="list-project-info-text">Mexican CRM <strong>Gestorsolar</strong> for professional management of multiple solar projects with specialized tools for the sector.</li>
                        <li className="list-project-info-text">My role was to create a landing page that can present the company's business in the most concise and informative way. And also showing as possible flexible system of tariff plans, depending on the number of users.</li>
                 

                        <li><a className="footer-link" href="https://www.behance.net/gallery/89414429/UIUX-Design-for-Gestorsolar" target="_blank" rel="noopener noreferrer">View this project on Behance &#8594;</a></li>
                      </ul>
                       
                    </div>
                </div>
                <div data-aos="fade-up" className="gestrosolar-images">
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar1.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar2.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar3.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar4.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar5.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar6.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar7.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar8.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar9.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar10.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar11.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Gestorsolar/Gestorsolar12.jpg')} alt="sf" />
                </div>
               
                {/* <hr /> */}
            </div>
        </div>
        </>
    );
}

export default Gestorsolar;