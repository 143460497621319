import React from 'react';
import './Turnkey.css';


function Turnkey() {
    return (
        <>
            <div className="main-turnkey">
             <div className="projects-container">
                    <div data-aos="fade-up" className="row-gest-one">
                         {/* Column 1 */}
                         <div className="gest-col-1">
                         
                         <h4 className="row-h4">CASE STUDY</h4>
                         <h1 className="row-gest-h1">UX/UI — design for TurnKey, startup</h1>
                        <ul className="my-role">
                        <h4 className="row-h4">MY ROLE</h4>
                        <div className="my-role-list">
                            <li>Creative Direction</li>
                            <li>UX / UI</li>
                            <li>Website Design</li>
                            <li>Icon Design</li>
                            </div>
                                    </ul>
                        <ul className="client"> 
                        <h4 className="row-h4">CLIENT</h4>
                        <li>Turnkey</li></ul>

                        <ul className="year"> 
                        <h4 className="row-h4">YEAR</h4>
                        <li>2019-2020</li></ul>
                                            
                      </div>
                
    
        
    
                    {/* Column 2 */}
                    <div className="gest-col-2">
                        <ul className="list-project-info">
                        <li className="list-project-info-text"><strong>Turnkey</strong> Startup is a step by step course 
to help people start a new business from a-z
setting up gmail, to getting a logo from 
99 designs, to setting up Quickbooks Online. 
Our instructors are the CEOs of some of the 
top SF tech companies and LA celebrity 
brand builders.</li>
                       
                        {/* <li><a className="footer-link" href="https://www.behance.net/gallery/88322561/Sellfashion-laxury-seller-uiux-design" target="_blank" rel="noopener noreferrer">View this project on Behance &#8594;</a></li> */}
                      </ul>
                       
                    </div>
                </div>
                <div data-aos="fade-up" className="turnkey-images">
                <img className="project-in-img" src={require('../../images/Turnkey/Turnkey1.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Turnkey/Turnkey2.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Turnkey/Turnkey3.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Turnkey/Turnkey4.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Turnkey/Turnkey5.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Turnkey/Turnkey6.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Turnkey/Turnkey7.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Turnkey/Turnkey8.jpg')} alt="sf" />
                <img className="project-in-img" src={require('../../images/Turnkey/Turnkey9.jpg')} alt="sf" />
                </div>
               
                {/* <hr /> */}
            </div>
        </div>
        </>
    );
}

export default Turnkey;