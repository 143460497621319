import React, { useState, useRef } from 'react';
import './Header.css'
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useOnClickOutside } from '../hooks';
import { GlobalStyles } from '../global';
import { theme } from '../theme'
import { Burger, Menu } from '../components/';
import { ReactComponent as Logo } from "../images/logo.svg";

// class Header extends React.Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       hasScrolled: false
//     }
//   }

// componentDidMount() {
//   window.addEventListener('scroll',
//   this.handleScroll)
// }

// handleScroll = (event) => {
//   const scrollTop = window.pageYOffset

// if (scrollTop > 50) {
//   this.setState({ hadScrolled: true })
// } else {
//   this.setState({ hasScrolled: false }) 
// }
// }

// useOnClickOutside(node) {
//   this.setState({ setOpen: false })
// }

//   const [open, setOpen] = useState(false);
//   const node = useRef()


function Header(props) {

  const [open, setOpen] = useState(false);
  const node = useRef();
  const [Header, setHeader] = useState(false)
  
  useOnClickOutside(node, () => setOpen(false));

const changeBackground = () => {
  if(window.scrollY >= 80) {
    setHeader(true);
  } else {
    setHeader(false);
  }
};

window.addEventListener('scroll', changeBackground);

  return (
    <ThemeProvider theme={theme}>
      <>
      <div ref={node}>
      <GlobalStyles />
      <Menu open={open} setOpen={setOpen} />
      <div className={Header? 'Header active' : 'Header'}>
      <div className="HeaderGroup">
        <Link to="/"><Logo className="logo-home" /></Link>
        <Burger open={open} setOpen={setOpen} />
      </div>
      </div>
      
      <div className="post">
      <Link className="post-link" to="/contact"><img className="post-logo" src={require('../images/post.svg')} alt="go to contact"/></Link>
      </div>
      </div>
      </>
      </ThemeProvider>
   )
  }

export default Header;